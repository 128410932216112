/* 清除内外边距 */
body,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
blockquote,
/* structural elements 结构元素 */
dl,
dt,
dd,
ul,
ol,
li,
/* list elements 列表元素 */
pre,
/* text formatting elements 文本格式元素 */
fieldset,
button,
input,
textarea,
/* form elements 表单元素 */
th,
td {
  /* table elements 表格元素 */
  margin: 0;
  padding: 0;
}

body {
  font-family: "lucida grande", "lucida sans unicode", lucida, helvetica, "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
}

address,
cite,
dfn,
em,
var {
  font-style: normal;
}

/* 重置列表元素 */
ul,
ol {
  list-style: none;
}

/* 重置文本格式元素 */
a {
  color: var(--inbiz-color-primary);
  text-decoration: none;
}

abbr[title],
acronym[title] {
  /* 注：1.ie6 不支持 abbr; 2.这里用了属性选择符，ie6 下无效果 */
  border-bottom: 1px dotted;
  cursor: help;
}

q::before,
q::after {
  content: '';
}

/* 重置表单元素 */
legend {
  color: #000;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* for ie6 */
fieldset,
img {
  border: none;
}

/**
 * Remove the border on images inside links in IE 10.
 */

img {
  border-style: none;
}

/* img 搭车：让链接里的 img 无边框 */

/* 注：optgroup 无法扶正 */
button,
input,
select,
textarea {
  font-size: 100%;

  /* 使得表单元素在 ie 下能继承字体大小 */
}

button:focus {
  outline: 0;
}

/* 重置表格元素 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* 重置 hr */
hr {
  height: 1px;
  border: none;
}

/* 让非ie浏览器默认也显示垂直滚动条，防止因滚动条引起的闪烁 */

/* Forms
   ========================================================================== */

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-size: 100%;
  font-family: inherit;
  line-height: 1.15;
}

/**
  * Show the overflow in IE.
  * 1. Show the overflow in Edge.
  */

button,
input {
  overflow: visible;
}

/**
  * Remove the inheritance of text transform in Edge, Firefox, and IE.
  * 1. Remove the inheritance of text transform in Firefox.
  */

button,
select {
  text-transform: none;
}

/**
  * Correct the inability to style clickable types in iOS and Safari.
  */

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

/**
  * Remove the inner border and padding in Firefox.
  */

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

/**
  * Restore the focus styles unset by the previous rule.
  */

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Remove the default vertical scrollbar in IE 10+.
 */

textarea {
  overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

.alignRight {
  text-align: right;
}

.scrollbarHover {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;
  }

  &:hover {
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #ddd;
    }

    /* 定义滚动条轨道内阴影+圆角 */
    &::-webkit-scrollbar-track {
      background-color: #f5f5f5;
      border-radius: 4px;

      --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    /* 定义滑块内阴影+圆角 */
    &::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 4px;

      --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
  }
}